<template>
  <footer id="nrw-footer" class="container" role="contentinfo">
    <footer-top/>
    <footer-bottom/>
  </footer>
</template>

<script>
import FooterTop from './FooterTop.vue'
import FooterBottom from './FooterBottom.vue'

export default {
  name: 'Footer',
  components: {
    footerTop: FooterTop,
    footerBottom: FooterBottom
  }
}
</script>

<style lang="scss">
  #nrw-footer {
    background-color: #003064  !important;
    color: white !important;
    padding: 20px 35px 0;
    line-height: 1.6;
    font-family: "BentonSans-Regular", "Open Sans", arial, sans-serif;
    font-size: 12px;
    font-weight: bold;

    a {
      color: #fff;
    }
  }
</style>
