import bg from './lang/bg.json'
import cs from './lang/cs.json'
import da from './lang/da.json'
import de from './lang/de.json'
import el from './lang/el.json'
import en from './lang/en.json'
import es from './lang/es.json'
import et from './lang/et.json'
import fi from './lang/fi.json'
import fr from './lang/fr.json'
import ga from './lang/ga.json'
import hr from './lang/hr.json'
import hu from './lang/hu.json'
import it from './lang/it.json'
import lt from './lang/lt.json'
import lv from './lang/lv.json'
import mt from './lang/mt.json'
import nl from './lang/nl.json'
import no from './lang/no.json'
import pl from './lang/pl.json'
import pt from './lang/pt.json'
import ro from './lang/ro.json'
import sk from './lang/sk.json'
import sl from './lang/sl.json'
import sv from './lang/sv.json'

export default {
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  ga,
  hr,
  hu,
  it,
  lt,
  lv,
  mt,
  nl,
  no,
  pl,
  pt,
  ro,
  sk,
  sl,
  sv
}
