<template>
  <div class="header-bottom-container" role="navigation">
    <div class="shadow"/>
    <ul class="menu-top-wrapper">
      <li class="nav-item" v-for="(item, index) in navItems" :key="index">
        <a class="nav-item-link" :href="item.href">
          <img class="nav-item-img" :src="require(`../assets/img/${item.src}`)" :alt="`Grafik: ${item.title}`"  :title="item.title"/>
          <span class="nav-item-text">{{ item.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HeaderBottom',
  data () {
    return {
      navItems: [{title: 'Open Government', href: '/open-government', src: 'open_government.svg', subItems: [{title: 'Open.NRW Projekte', href: '/open-government/opennrw-projekte'}, {title: 'Open Government Partnership', href: '/open-government-nrw/open-government-partnership'}, {title: 'Open Government Pakt', href: '/open-government/open-government-pakt'}, {title: 'Der Arbeitskreis', href: '/open-government/der-arbeitskreis'}]},
        {title: 'Open Data', href: '/open-data', src: 'open_data.svg', subItems: [{title: 'Open Data finden', href: '/suche?volltext='}, {title: 'Metadatenqualität', href: '/open-data/metadatenqualitaet'}, {title: 'Datenvalidierung', href: '/webseite_in_arbeit'}, {title: 'Informationen für Entwickler*innen', href: '/open-data/informationen-fuer-entwicklerinnen'}]},
        {title: 'Austausch', href: '/austauschen', src: 'dialog.svg', subItems: [{title: 'Termine', href: '/termine'}, {title: 'Netzwerk', href: '/austauschen/netzwerk'}]},
        {title: 'Unterstützung', href: '/unterstuetzung', src: 'unterstuetzung_beratung.svg', subItems: [{title: 'Publikationen', href: '/unterstuetzung/publikationen'}, {title: 'Software', href: '/unterstuetzung/software'}, {title: 'Für die Landesverwaltung', href: '/ueber-uns'}, {title: 'Für Kommunen', href: '/unterstuetzung/fuer-kommunen'}]},
        {title: 'Über uns', href: '/ueber-uns', src: 'ueber_uns.svg', subItems: []},
        {title: 'FAQ', href: '/faq', src: 'faq.svg', subItems: []},
        {title: 'Meine Daten', href: '/meine-daten', src: 'veroeffentlichen.svg', subItems: [{title: 'Hilfe', href: '/meine-daten/hilfe'}, {title: 'Metadatenqualität', href: '/open-data/metadatenqualitaet'}]}]
    }
  }
}

</script>

<style lang="scss" scoped>

  .header-bottom-container {
    width: 100%;
    background-color: #fff;
    line-height: 1.6;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .shadow {
    display: inline-block;
    padding-top: 11px;
    margin-top: 2px;
    margin-bottom: 0;
    background-image: url('../assets/img/main-menu-bg.png');
    background: transparent linear-gradient(90deg,rgba(222,222,222,0) 0%,rgba(222,222,222,0.66) 15%,#dedede 50%,rgba(222,222,222,0.66) 85%,rgba(222,222,222,0) 100%);
    width: 100%;
    color: #333;
  }

  .menu-top-wrapper {
    display: flex;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin-top: -8px;
    margin-bottom: 0;
    cursor: pointer;
  }

  .nav-item {
    max-width: 230px;
    min-height: 80px;
    background-color: white;
    border-bottom: 2px solid #fff;
    border-right: 1px solid rgba(0,48,100,0.1);
    flex-basis: 0;
    margin: 0;
    box-sizing: border-box;
    display: block;
    width: auto;
    flex-grow: 1;

    &:last-child {
      border-right: 0;
    }

    &:nth-child(2) {
      border-bottom: 2px solid #80D3DA;
      background-color: #f7f7f7;
    }

    &:hover {
      border-bottom: 2px solid #80D3DA;
      background-color: #f7f7f7;
    }
  }

  .nav-item-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 10px;
    text-decoration: none;
    color: var(--nrw-color) !important;
  }

  .nav-item-img {
    margin-bottom: 10px;
  }

  .nav-item-text {
    padding-bottom: 4px;
    text-align: center;
  }
</style>
