<template>
  <nav class="header-top-container" role="navigation">
    <div class="mobile-dropdown-wrapper" @click="toggleMenu">
      <div class="dropdown-title">TECHNISCHES MENU</div>
      <div v-if="isTechnicalMenuOpen">
        <i class="fas fa-angle-up" aria-hidden="true"/>
      </div>
      <div v-else>
        <i class="fas fa-angle-down" aria-hidden="true"/>
      </div>
    </div>
    <div class="header-top-link-wrapper" :class="{'hide': !isTechnicalMenuOpen}">
      <ul class="ul-wrapper">
        <li class="first-element">
          <a href="/leichte-sprache">
            <img class="icons" src="../assets/img/book.svg" alt="Grafik: Leichte Sprache" title="Leichte Sprache" />Leichte Sprache
            </a>
        </li>
        <li>
          <a href="/gebaerdensprache">
            <img class="icons" src="../assets/img/hands.svg" alt="Grafik: Gebärdensprache" title="Gebärdensprache" />Gebärdensprache
            </a>
        </li>
      </ul>
      <ul class="ul-wrapper">
        <li>
          <a href="/cas/data-provider-login">
            <img class="icons" src="../assets/img/login.svg" alt="Grafik: Anmelden" title="Gebärdensprache" />Anmelden / Registrieren
            </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'HeaderTop',
  data () {
    return {
      isTechnicalMenuOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.isTechnicalMenuOpen = !this.isTechnicalMenuOpen
    }
  }
}

</script>

<style lang="scss" scoped>
  .header-top-container {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 42px;
    font-size: 10px;
    background-color: var(--nav-grey);
    position: relative;
    line-height: 25px;

    @media only screen and (max-width: 720px) {
      min-height: 65px;
    }
  }

  .mobile-dropdown-wrapper {
    @media only screen and (max-width: 720px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .dropdown-title {
    display: none;

    @media only screen and (max-width: 720px) {
      display: block;
    }
  }

  .fas {
    display: none;

    @media only screen and (max-width: 720px) {
      display: block;
      margin-left: 10px;
    }
  }

  .header-top-link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 720px) {
      position: absolute;
      display: block;
      background-color: #fff;
      width: 100%;
      top: 65px;
      padding-bottom: 5px;
      box-shadow: 0 5px 5px rgba(0,0,0,0.5);
      z-index: 10;
    }
  }

  .hide {
    @media only screen and (max-width: 720px) {
      display: none;
    }

  }

  .ul-wrapper {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-right: 20px;

    @media only screen and (max-width: 720px) {
      display: block;
      padding: 0 20px;
    }
  }

  li {
    @media only screen and (max-width: 720px) {
      border-bottom: 1px solid rgba(0,48,100,0.1);
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 12px;
    }
  }

  .first-element {
    @media only screen and (max-width: 720px) {
      border-top: 1px solid rgba(0,48,100,0.1);
    }
  }

  a {
    line-height: 2.4;

    @media only screen and (max-width: 720px) {
      padding: 10px 0;
      font-weight: normal;
      text-transform: uppercase;
      width: 100%;
      line-height: 2.4;
    }
  }

  .icons {
    margin-right: 2px;
    padding-left: 10px;

    @media only screen and (max-width: 720px) {
      padding-left: 0;
    }
  }
</style>
