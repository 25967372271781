<template>
  <div id="app">


    <back-to-top bottom="20px" right="20px" visibleoffsetbottom="200" >
      <button type="button" class="btn btn-info btn-to-top"><i class="fa fa-angle-up" aria-hidden="true"></i></button>
    </back-to-top>
    <open-nrw-header/>
    <div class="bg container" >
<!--      <breadcrumb />-->
      <subnavigation :key= "$route.params.id"></subnavigation>
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
    <open-nrw-footer/>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import SubNavigation from './components/SubNavigation'
// import Breadcrumb from './components/ec/Breadcrumb'

export default {
  name: 'app',
  components: {
    subnavigation: SubNavigation,
    openNrwHeader: Header,
    openNrwFooter: Footer
    // breadcrumb: Breadcrumb
  },
  data () {
    return {
      tracker: null,
      matomoURL: this.$env.MATOMO_URL,
      lastRoute: null,
      piwikId: this.$env.PIWIK_ID,
      showSparql: this.$env.SHOW_SPARQL
    }
  },
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.$route.query.locale
      }
    }
  },
  methods: {
    handleFollowClick (url) {
      this.$piwik.trackOutlink(url)
    },
    login() {
      window.location.href = "https://data.europa.eu/euodp/data/user/login";
    },
    logout() {

    }
  }
}
</script>

<style lang="scss">
@import './styles/metrics-style.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

//@font-face {
//  font-family: "Ubuntu";
//  src: local("Ubuntu"), url(../static/fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
//}

* {
  box-sizing: border-box;
}

.site-wrapper {
  border: 1px solid #83b4c2;
  margin: auto;
  max-width: 984px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.spacer {
  margin: 500px;
}

#app {
  background-color: #f4f4f4;
}

.bg {
  background-color: #fff;
}

</style>
