<template>
  <div class="footer-top-container">
  </div>
</template>

<script>

export default {
  name: 'FooterTop'
}
</script>

<style lang="scss" scoped>
  .footer-top-container {
    width: 100%;
    border-bottom: 1px solid #fff;
  }
</style>
