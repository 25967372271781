<template>
  <div class="footer-bottom-container">
    <div class="copyright">
      Copyright: © 2021 - {{ new Date().getFullYear() }} Ministerium für Heimat, Kommunales, Bau und Digitalisierung des Landes Nordrhein-Westfalen
    </div>
    <div class="nav-wrapper">
      <nav class="w-100" aria-labelledby="block-fusszeile-menu">
        <h2 class="visually-hidden">Fußzeile</h2>
        <ul class="link-wrapper">
          <li v-for="(item, index) in navItems" :key="index">
            <a :href="item.href">{{ item.title }}</a>
            {{ " " }}
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FooterBottom',
  data () {
    return {
      navItems: [{title: 'Impressum', href: 'https://open.nrw/impressum'},
        {title: 'Datenschutz', href: 'https://open.nrw/datenschutzerklaerung'},
        {title: 'Kontakt', href: 'https://open.nrw/kontakt'},
        {title: 'Barrierefreiheit', href: 'https://open.nrw/barrierefreiheit'}]
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-bottom-container {
    display: flex;
    width: 100%;
    padding: 20px 0;

    @media only screen and (max-width: 991.9px) {
      flex-direction: column;
    }
  }

  .copyright {
    width: 50%;
    padding-right: 20px;

    @media only screen and (max-width: 991.9px) {
      width: 100%;
    }
  }

  .nav-wrapper {
    display: flex;
    width: 50%;

    @media only screen and (max-width: 991.9px) {
      width: 100%;
    }
  }

  .visually-hidden {
    position: absolute;
    visibility: hidden;
  }

  .link-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-decoration: none;
    margin: 0;
    margin-top: 5px;

    @media only screen and (max-width: 991.9px) {
      justify-content: flex-start;
      padding: 0;
    }
  }

  li {
    list-style: none;
    color: white;

    &::after {
      content: "|";
      padding: 0 2px;

    }
    &:last-child::after {
      content: "";
      padding: 0;
    }

    @media only screen and (max-width: 991.9px) {
      &:first-child {
        a {
          padding-left: 0 !important;
        }
      }
    }
  }

  a {
    color: white;
    padding: 5px;
  }
</style>
