<template>
  <header id="nrw-header" class="bg container">
    <!-- <header-top/> -->
    <header-middle/>
    <!-- <header-bottom/> -->
  </header>
</template>

<script>
import HeaderTop from './HeaderTop'
import HeaderMiddle from './HeaderMiddle'
import HeaderBottom from './HeaderBottom'

export default {
  name: 'Header',
  components: {
    headerTop: HeaderTop,
    headerMiddle: HeaderMiddle,
    headerBottom: HeaderBottom
  }
}

</script>

<style lang="scss">
  #nrw-header {
    font-family: "BentonSans" !important;
    font-weight: bold !important;
    color: var(--nrw-color) !important;
  }
</style>
